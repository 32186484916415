.footer-wrapper {
  background: #F3F3F3;
  padding: 17px 0;
  color: #828282;
  font-size: 14px;

  .main-container {
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    align-items: center;
  }

  .copyright {
    font-size: 12px;
  }

  a {
    color: #3B80E8;
    text-decoration: none;
  }

  .social-links {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      padding: 0 10px;
      list-style: none;
    }
  }
}
.main-menu {
  display: flex;
  margin: 0;

  li {
    list-style: none;
    padding-right: 44px;
    a {
      text-decoration: none;
      color: #828282;
    }
  }
}
