.text-center {
  text-align: center;
}

.tabs__head {
  &-list {
    padding: 0;
    text-align: center;
  }

  &-item {
    display: inline-block;
    margin: 5px;
  }

  &-button {
    background: hsla(0, 0%, 92%, 1);
    padding: 8px 12px;
    border-radius: 40px;
    border: 0;
    line-height: 24px;
    font-size: 13px;
    cursor: pointer;

    &--active {
      background: hsla(216, 79%, 57%, 1);
      color: white;
    }
  }
}

.card {
  border: 1px solid hsla(0, 0%, 89%, 1);
  border-radius: 4px;
  padding: 28px 35px;
  margin-bottom: 22px;

  &-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    h3 {
      margin: 0;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .btn {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  iframe {
    border: 0;
  }

  .card-content {
    padding-top: 20px;
  }
}
