@font-face {
  font-family: ProximaNova;
  src: url(view/assets/fonts/proxima-nove-400.eot);
  src: url(view/assets/fonts/proxima-nove-400.eot?#iefix) format("embedded-opentype"),
  url(view/assets/fonts/proxima-nove-400.woff2) format("woff2"),
  url(view/assets/fonts/proxima-nove-400.woff) format("woff"),
  url(view/assets/fonts/proxima-nove-400.ttf) format("truetype"),
  url(view/assets/fonts/proxima-nove-400.svg#wf) format("svg");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: ProximaNova;
  src: url(view/assets/fonts/proxima-nove-700.eot);
  src: url(view/assets/fonts/proxima-nove-700.eot?#iefix) format("embedded-opentype"),
  url(view/assets/fonts/proxima-nove-700.woff2) format("woff2"),
  url(view/assets/fonts/proxima-nove-700.woff) format("woff"),
  url(view/assets/fonts/proxima-nove-700.ttf) format("truetype"),
  url(view/assets/fonts/proxima-nove-700.svg#wf) format("svg");
  font-weight: 700;
  font-style: normal
}

body {
  margin: 0;
  font-family: ProximaNova, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: #4F4F4F;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.main-container {
  max-width: 905px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0 auto;
  flex: 1 1 auto;
}

.d-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

h1 {
  font-size: 52px;
  color: #333333;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 0;
  span {
    color: #3B80E8;
  }
}

.main-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 20px 0;
  box-sizing: border-box;
  @media (min-width: 780px) {
   flex-direction: row;
  }
}
.main-screen-content {
  max-width: 485px;
  .store-block {
    display: flex;
    padding: 0;
    li {
      list-style: none;
      padding: 15px 15px 15px 0;
    }
  }
}
