.header-container {
  display: flex;
  justify-content: space-between;
  padding: 23px 10px;
  align-items: center;
  max-width: 1160px;
  box-sizing: border-box;
  margin: 0 auto;

  .main-menu {
    display: flex;

    li {
      list-style: none;
      padding-left: 44px;
      a {
        text-decoration: none;
        color: #828282;
      }
    }
  }
}
