ol { counter-reset: item;
  & > li {
    display: block;
    position: relative;
    padding-left: 20px;
    &:before {
      content: counters(item, ".") " ";
      counter-increment: item;
      position: absolute;
      left: 0;
    }
    & > ol > li {
      padding-left: 40px;
    }
  }
  & > li {
    &:before {
      font-size: 20px;
    }
  }
}
ul {
  li {
    list-style: disc;
  }
}
